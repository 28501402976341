import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { HandleDownload } from '@/services/DownloadHandler';
import { MonitoringAnalysisData } from '@/services/api';

type ActiveInactiveProps = {
  data: MonitoringAnalysisData[];
  showSubtitle: boolean;
};

export function ActiveInactivePlot({ data, showSubtitle }: ActiveInactiveProps) {
  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const plotData = [
    data.filter((obj) => obj.activeMobility).reduce((total, current) => total + current.count, 0),
    data.filter((obj) => !obj.activeMobility).reduce((total, current) => total + current.count, 0),
  ];
  const filterdData = [
    {
      values: plotData,
      labels: [t('MonitoringAnalysisActiveLabel'), t('MonitoringAnalysisInactiveLabel')],
      type: 'pie',
      hole: 0.4,
      texttemplate: '%{percent:.0%}<br>%{label}',
      marker: {
        colors: [CommuteUtils.SCOOTER.color, CommuteUtils.CAR_MOTORCYCLE.color],
        line: {
          color: theme.palette.tertiary.light,
          width: 1,
        },
      },
      hoverinfo: 'label+value',
    },
  ];
  const header = ['Mobilitätsform', 'Anteil', 'in Prozent'];
  const csvData = filterdData.flatMap((param) =>
    param.values.map((value, index) => {
      const total = param.values.reduce((acc, currentValue) => acc + currentValue, 0);
      const percent = ((value / total) * 100).toFixed(1);

      return {
        label: param.labels[index],
        values: value,
        percent: Number(percent).toLocaleString('de-DE'),
      };
    }),
  );

  return (
    <>
      <Button onClick={() => HandleDownload({ csvData, header, name: 'Aktiver Anteil' })}>Download CSV</Button>
      <Plot
        data={filterdData as any}
        layout={{
          title: `${t('MonitoringAnalysisActiveInactivePlotTitle')}${
            showSubtitle ? `<br><sub>${t('MonitoringAnalysisActiveInactivePlotSubtitle')}</sub>` : ''
          }`,
          showlegend: false,
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
        config={{ locale: i18n.language }}
      />
    </>
  );
}
