import { Box, Button, css, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { HandleDownload } from '@/services/DownloadHandler';
import { MonitoringAnalysisData } from '@/services/api';
import { theme } from '@/setup/theme';

type MovementAmountProps = {
  data: MonitoringAnalysisData[];
  showIcons: boolean;
  showSubtitle: boolean;
};

const StyledBox = styled(Box)(
  () => css`
    position: relative;

    .js-plotly-plot .plotly [data-title]::after {
      white-space: pre;
      line-height: 16px;
    }
  `,
);

export function MovementAmount({ data, showIcons, showSubtitle }: MovementAmountProps) {
  const { t, i18n } = useTranslation();
  const plotData = data.filter((obj) => obj.movementShare !== 0);
  const filterdData = [
    {
      x: plotData.map((value) => t(`CommuteTypeShort.${value.commuteType}`)),
      y: plotData.map((value) => Number(value.movementShare.toFixed(0))),
      text: plotData.map((value) => value.movementShare.toLocaleString(undefined, { maximumFractionDigits: 1 })),
      textposition: 'outside',
      type: 'bar',
      marker: {
        color: plotData.map((obj) => CommuteUtils[obj.commuteType].color),
      },
    },
  ];

  const header = ['Verkehrsmittel', 'Mobilität in Minuten'];
  const csvData = filterdData
    .flatMap((param) =>
      param.x.map((value, index) => ({
        value,
        text: param.y[index].toLocaleString('de-DE'),
      })),
    )
    .sort((a, b) => a.value.localeCompare(b.value));

  return (
    <>
      <Button onClick={() => HandleDownload({ csvData, header, name: 'Bewegungsanteil' })}>Download CSV</Button>
      <StyledBox sx={{ position: 'relative' }}>
        <Plot
          data={filterdData as any}
          layout={{
            title: `${t('MonitoringAnalysisMovementAmountTitle')}${
              showSubtitle ? `<br><sub>${t('MonitoringAnalysisMovementAmountSubtitle')}</sub><br>` : ''
            }`,
            yaxis: { title: t('MonitoringAnalysisMovementAmountYaxis'), fixedrange: true },
            xaxis: { fixedrange: true, showticklabels: !showIcons },
            images: showIcons
              ? plotData.map((obj, index) => ({
                  source: `data:image/svg+xml;utf8,${encodeURIComponent(CommuteUtils[obj.commuteType].raw.replaceAll('currentColor', theme.palette.primary.main))}`,
                  xref: 'paper',
                  yref: 'paper',
                  x: (index + 0.5) / plotData.length,
                  y: -0.02,
                  sizex: 0.15,
                  sizey: 0.15,
                  layer: 'above',
                  sizing: 'contain',
                  xanchor: 'center',
                  yanchor: 'top',
                }))
              : [],
          }}
          useResizeHandler
          style={{ width: '100%', height: '100%', minHeight: '600px' }}
          config={{
            modeBarButtonsToAdd: [
              {
                icon: {
                  width: 23.6,
                  height: 23.6,
                  path: 'M11.8,0C5.3,0,0,5.3,0,11.8c0,2.2,0.6,4.4,1.8,6.3L0,23.6l5.8-1.6v0c1.8,1.1,3.9,1.7,6,1.6c6.5,0,11.8-5.3,11.8-11.8C23.6,5.3,18.3,0,11.8,0z M13.3,18.4c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V10c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5V18.4z M11.8,7c-0.8,0-1.5-0.7-1.5-1.5v0C10.3,4.6,11,4,11.8,4s1.5,0.7,1.5,1.5S12.6,7,11.8,7z',
                },
                name: 'Info',
                title: t('MonitoringAnalysisMovementAmountInfo'),
                click: () => {},
              },
            ],
            locale: i18n.language,
          }}
        />
      </StyledBox>
    </>
  );
}
