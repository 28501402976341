import { Box, css, darken, IconButton, styled, Theme } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import logoBmm from '@/assets/icons/bmm-logo.svg?url';
import Info from '@/assets/icons/info.svg?react';
import logoIvm from '@/assets/icons/ivm-logo.png';
import logoSmm from '@/assets/icons/smm-logo.svg?url';
import backgroundImage from '@/assets/images/shutterstock.jpg';
import { MonitoringDialog } from '@/components/form-elements/MonitoringDialog';
import { useQueryMonitoringLocation } from '@/hooks/queries/useQueryMonitoringLocation';
import { MonitoringStyle } from '@/services/api';
import { theme as outerTheme } from '@/setup/theme';
import { transientOptions } from '@/utils/transientOptions';

const Root = styled(
  Box,
  transientOptions,
)<{ $backgroundImage: string }>(
  ({ theme, $backgroundImage }) => css`
    position: relative;
    min-height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(${$backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};

    ${theme.breakpoints.up('sm')} {
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    }
  `,
);

const Wrapper = styled(Box)(
  ({ theme }) => css`
    max-width: ${theme.spacing(110)};
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: ${theme.spacing(2)};
  `,
);

const themeSmm = createTheme(outerTheme, {
  palette: {
    primary: {
      main: '#FFF200',
      dark: darken('#FFF200', 0.1),
      contrastText: '#3a3a3a',
      light: '#fbfdda',
    },
    secondary: {
      main: '#d1d3d4',
    },
  },
});
const themeIvm = createTheme(outerTheme, {
  palette: {
    primary: {
      main: '#7abef6',
      contrastText: '#3a3a3a',
      dark: darken('#cbe3f5', 0.1),
      light: '#cbe3f5',
    },
    secondary: {
      main: '#F0F0F0',
    },
  },
});

export function MonitoringThemeProvider() {
  const { data: location } = useQueryMonitoringLocation();

  const [open, setOpen] = useState(false);

  return (
    location && (
      <ThemeProvider
        theme={
          {
            [MonitoringStyle.SMM]: themeSmm,
            [MonitoringStyle.IVM]: themeIvm,
            [MonitoringStyle.BMM]: (theme: Theme) => theme,
          }[location.monitoringStyle]
        }
      >
        <Root
          $backgroundImage={
            {
              [MonitoringStyle.BMM]: backgroundImage,
              [MonitoringStyle.SMM]: backgroundImage,
              [MonitoringStyle.IVM]: backgroundImage,
            }[location.monitoringStyle]
          }
        >
          <Box
            component="img"
            src={
              {
                [MonitoringStyle.BMM]: logoBmm,
                [MonitoringStyle.SMM]: logoSmm,
                [MonitoringStyle.IVM]: logoIvm,
              }[location.monitoringStyle]
            }
            alt={
              {
                [MonitoringStyle.BMM]: 'Besser zur Arbeit',
                [MonitoringStyle.SMM]: 'Besser zur Schule',
                [MonitoringStyle.IVM]: 'ivm RheinMain',
              }[location.monitoringStyle]
            }
            sx={{
              width: 'auto',
              height: location.monitoringStyle === MonitoringStyle.IVM ? 84 : 64,
              position: 'absolute',
              top: location.monitoringStyle === MonitoringStyle.IVM ? 6 : 16,
              left: location.monitoringStyle === MonitoringStyle.IVM ? 6 : 16,
            }}
          />
          <Wrapper>
            <Outlet />
          </Wrapper>

          <IconButton onClick={() => setOpen(true)} color="primary" sx={{ position: 'absolute', bottom: 16, left: 16 }}>
            <Info />
          </IconButton>

          <MonitoringDialog open={open} onClose={() => setOpen(false)} />
        </Root>
      </ThemeProvider>
    )
  );
}
