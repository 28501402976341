import {
  AdminLevelType,
  BuildingType,
  BusinessTripOrganizationType,
  BusinessTripTransportationType,
  ConsultingStatus,
  NetworkCategory,
  ParkingFeeType,
  ParkingFeePeriod,
  ParkingLocation,
  ParkingPermitAllocationType,
  Sector,
  UserStatus,
  YesNoPartly,
  SurveyImportance,
  BicycleParkingLocationType,
  LoadFactor,
  BicycleChangingType,
  ManagementInstrumentType,
  SafeAndEcoDrivingIncentive,
  PublicTransportInformationType,
  PublicTransportFundingType,
  CarFinancialFundingType,
  BicycleIncentiveType,
  CarSharingPromotionType,
  DistanceType,
  GoodMediumBad,
  LocationAreaType,
  NeighbourhoodUsage,
  NeighbourhoodBicycleInfrastructure,
  PedestrianPathType,
  DurationType,
  ArrivalCount,
  FillStatus,
  UserRole,
  OrganizationUserRole,
  LocationProfileStatus,
  IndicatorError,
  IndicatorRelationStatus,
  IndicatorType,
  IndicatorProcessStatus,
  CommuteType,
} from '@/services/api';

export const en = {
  AdminLevelType: {
    [AdminLevelType.GOVERNMENT_DISTRICT]: 'Regierungsbezirk',
    [AdminLevelType.COUNTY]: 'Landkreis',
    [AdminLevelType.FREE_CITY]: 'Kreisfreie Stadt',
    [AdminLevelType.MUNICIPALITY]: 'Gemeinde',
    [AdminLevelType.CITY]: 'Stadt',
    [AdminLevelType.MUNICIPALITY_FREE_AREA]: 'Gemeindefreies Gebiet',
  },
  BicycleChangingType: {
    [BicycleChangingType.SHOWER]: 'Duschen',
    [BicycleChangingType.CHANGING_ROOM]: 'Umkleiden',
    [BicycleChangingType.LOCKER]: 'Spinde',
  },
  BicycleParkingLocationType: {
    [BicycleParkingLocationType.BUILDING]: 'Im Gebäude/Raum',
    [BicycleParkingLocationType.OUTDOOR]: 'Im Freien',
    [BicycleParkingLocationType.OTHER]: 'Sonstiges',
  },
  BuildingType: {
    [BuildingType.OWN_PROPERTY]: 'Eigentum',
    [BuildingType.RENTAL_PROPERTY]: 'Mietobjekt',
  },
  BusinessTripOrganizationType: {
    [BusinessTripOrganizationType.CENTRAL]: 'Zentral',
    [BusinessTripOrganizationType.DECENTRAL]: 'Dezentral (Abteilungen eigenständig zuständig)',
    [BusinessTripOrganizationType.OTHER]: 'Sonstiges',
  },
  BusinessTripTransportationType: {
    [BusinessTripTransportationType.AIRCRAFT]: 'Flugzeug',
    [BusinessTripTransportationType.BICYCLE]: 'Fahrrad/Lastenrad',
    [BusinessTripTransportationType.SERVICE_BICYCLE]: 'Dienstrad',
    [BusinessTripTransportationType.BICYCLE_RENT]: 'Fahrradvermietsystem',
    [BusinessTripTransportationType.CAR_SHARING]: 'Car-Sharing',
    [BusinessTripTransportationType.ORGANIZATION_CAR]: 'Dienstwagen',
    [BusinessTripTransportationType.PRIVATE_CAR]: 'Eigener PKW',
    [BusinessTripTransportationType.POOL_CAR]: 'Poolfahrzeug',
    [BusinessTripTransportationType.PUBLIC_TRANSPORT]: 'Öffentliche Verkehrsmittel',
    [BusinessTripTransportationType.RENTAL_CAR]: 'Mietwagen',
    [BusinessTripTransportationType.OTHER]: 'Sonstiges',
  },
  ConsultingStatus: {
    [ConsultingStatus.CONTACT_REQUEST]: 'Kontaktanfrage',
    [ConsultingStatus.LETTER_OF_INTENT]: 'LOI',
    [ConsultingStatus.CONSULTING]: 'Beratung',
    [ConsultingStatus.MOBILITY_PLAN]: 'Mobilitätsplan',
    [ConsultingStatus.AUDIT]: 'Audit',
    [ConsultingStatus.RE_AUDIT]: 'Re-Audit',
    [ConsultingStatus.COMPLETED]: 'Archiv',
  },
  LoadFactor: {
    [LoadFactor.LOW_LOAD]: 'Geringe Auslastung',
    [LoadFactor.WELL_LOADED]: 'Gut ausgelastet',
    [LoadFactor.OVERLOADED]: 'Überlastet',
  },
  NetworkCategory: {
    [NetworkCategory.REGION]: 'Region',
    [NetworkCategory.TRANSPORT_ASSOCIATION]: 'Verkehrsverbund',
    [NetworkCategory.BUSINESS_PARK]: 'Gewerbegebiet',
    [NetworkCategory.IHK_DISTRICT]: 'IHK-Kammerbezirk',
    [NetworkCategory.SHAREHOLDER_DISTRICT]: 'Gesellschaftergebiet',
    [NetworkCategory.COOPERATION]: 'Kooperation',
    [NetworkCategory.QUARTER]: 'Quartier',
    [NetworkCategory.ORGANIZATION_TYPE]: 'Organisationstyp',
  },
  ParkingFeeType: {
    [ParkingFeeType.NO]: 'Nein, entgeltfrei',
    [ParkingFeeType.YES]: 'Ja, alle Stellplätze gebührenpflichtig',
    [ParkingFeeType.PARTLY]: 'Ja, Stellplätze teilweise gebührenpflichtig',
    [ParkingFeeType.PAY_AS_YOU_USE]: 'Nutzungsbezogene Gebühr (genutzte Stunde/Tag)',
    [ParkingFeeType.FLAT_RATE]: 'Pauschale Gebühr (Jahr/Monat)',
  },
  ParkingFeePeriod: {
    [ParkingFeePeriod.DAY]: 'Tag',
    [ParkingFeePeriod.WEEK]: 'Woche',
    [ParkingFeePeriod.MONTH]: 'Monat',
    [ParkingFeePeriod.YEAR]: 'Jahr',
  },
  ParkingLocation: {
    [ParkingLocation.PARKING_LOT]: 'Ebenerdig',
    [ParkingLocation.UNDERGROUND_PARKING]: 'Tiefgarage',
    [ParkingLocation.PARKING_GARAGE]: 'Parkhaus',
  },
  ParkingPermitAllocationType: {
    [ParkingPermitAllocationType.NONE]: 'Keine',
    [ParkingPermitAllocationType.HIERARCHY]: 'Nach Betriebshierarchie',
    [ParkingPermitAllocationType.DISTANCE]: 'Nach Entfernung',
    [ParkingPermitAllocationType.SPECIAL_NEED]:
      'Bei ausgewiesenem Bedarf (Schichtarbeit, Behinderung o. körperliche Einschränkung, etc.)',
    [ParkingPermitAllocationType.OTHER]: 'Sonstiges',
  },
  Sector: {
    [Sector.MANUFACTURING_INDUSTRY]: 'Verarbeitendes Gewerbe/Herstellung von Waren',
    [Sector.ENERGY_WATER_SUPPLY]: 'Energie- und Wasserversorgung',
    [Sector.BUILDING_INDUSTRY]: 'Baugewerbe',
    [Sector.TRADE_HOSPITALITY_INDUSTRY]: 'Handel/Gastgewerbe',
    [Sector.TRANSPORT]: 'Verkehr (Personen und Güter)',
    [Sector.INFORMATION_COMMUNICATION]: 'Information und Kommunikation (IT, Medien)',
    [Sector.FINANCE_INSURANCE]: 'Banken/Finanz- und Versicherungsdienstleister',
    [Sector.PROPERTY_HOUSING]: 'Grundstücks- und Wohnungswesen',
    [Sector.SCIENCE]: 'Wissenschaftliche und technische Dienstleistungen (Beratung, Forschung, Entwicklung)',
    [Sector.ECONOMY_SERVICE]: 'Wirtschaftliche Dienstleistungen (Veranstalter, Sekretariat, Vermietung, Vermittlung)',
    [Sector.OTHER_SERVICES]: 'Sonstige Dienstleistungen („personenbezogene“ z.B. Friseur)',
    [Sector.CIVIL_SERVICE]: 'Öffentlicher Dienst (Verwaltung, Recht, Verteidigung)',
    [Sector.HEALTH_SOCIAL_SERVICE]: 'Gesundheits- und Sozialwesen (Erziehung, Unterricht)',
    [Sector.AGRICULTURE_FORESTRY_FISHERY]: 'Land-, Forstwirtschaft und Fischerei',
    [Sector.MINING]: 'Bergbau (Erdöl, Erdgas, Steine, Erden)',
    [Sector.OTHER]: 'Sonstige',
  },
  SurveyImportance: {
    [SurveyImportance.NOT_SPECIFIED]: 'Keine Angabe',
    [SurveyImportance.NOT_IMPORTANT]: 'Unwichtig',
    [SurveyImportance.LESS_IMPORTANT]: 'Weniger wichtig',
    [SurveyImportance.FAIRLY_IMPORTANT]: 'Ziemlich wichtig',
    [SurveyImportance.VERY_IMPORTANT]: 'Sehr wichtig',
  },
  ManagementInstrumentType: {
    [ManagementInstrumentType.OEKO_AUDIT]: 'Öko-Audit/EMAS',
    [ManagementInstrumentType.UMWELTMANAGEMENT_ISO14001]: 'Umweltmanagement nach DIN/EN/ISO 14001',
    [ManagementInstrumentType.OEKO_PROFIT]: 'Ökoprofit',
    [ManagementInstrumentType.UMWELTBERICHTERSTATTUNG]: 'Umweltberichterstattung',
    [ManagementInstrumentType.NACHHALTIGKEITSBERICHTERSTATTUNG]: 'Nachhaltigkeitsberichterstattung',
    [ManagementInstrumentType.UMWELTKOSTENRECHNUNG]: 'Umweltkostenrechnung',
    [ManagementInstrumentType.VERWALTUNGSMODERNISIERUNG]:
      'Verwaltungsmodernisierung (Personal, Organisation, Steuerung)',
    [ManagementInstrumentType.QUALITAETSMANAGEMENT_ISO9001]: 'Qualitätsmanagement (z.B. DIN/EN/ISO 9001, EFQM)',
    [ManagementInstrumentType.BETRIEBLICHE_GESUNDHEITSFOERDERUNG]: 'Betriebliche Gesundheitsförderung',
    [ManagementInstrumentType.SICHERHEIT_ARBEITSSCHUTZMANAGEMENT]:
      'Sicherheits- und Arbeitsschutzmanagement (z.B. OHSAS 18001)',
    [ManagementInstrumentType.OTHER]: 'Sonstiges',
  },
  PublicTransportInformationType: {
    [PublicTransportInformationType.FAHRPLANAUSHANG]: 'Fahrplanaushang',
    [PublicTransportInformationType.ZUGANG_INTRANET_FAHRPLAN]: 'Zugang Internet-Fahrplan',
    [PublicTransportInformationType.OTHER]: 'Sonstiges',
  },
  PublicTransportFundingType: {
    [PublicTransportFundingType.JOB_TICKET]: 'JobTicket',
    [PublicTransportFundingType.SUBSIDY_20_TO_50]: 'Zuschuss 20-50% der Abokosten',
    [PublicTransportFundingType.SUBSIDY_50]: 'Zuschuss >50% der Abokosten',
    [PublicTransportFundingType.OTHER]: 'Sonstiges',
  },
  CarFinancialFundingType: {
    [CarFinancialFundingType.COMPANY_CAR]: 'Dienstwagen',
    [CarFinancialFundingType.FREE_PARKING]: 'Kostenfreie Stellplätze',
    [CarFinancialFundingType.OTHER]: 'Sonstiges',
  },
  SafeAndEcoDrivingIncentive: {
    [SafeAndEcoDrivingIncentive.PAPER_HINT]: 'Papierhinweise',
    [SafeAndEcoDrivingIncentive.COURSE_OR_SIMULATOR]: 'Kursbesuch oder Simulator',
    [SafeAndEcoDrivingIncentive.NONE]: 'Keine',
  },
  BicycleIncentiveType: {
    [BicycleIncentiveType.FINANCIAL]: 'Durch finanzielle Anreize',
    [BicycleIncentiveType.SERVICES]: 'Durch Services (z.B. Reparatur)',
    [BicycleIncentiveType.LEASING]: 'Fahrradleasing',
    [BicycleIncentiveType.OTHER]: 'Sonstiges',
  },
  CarSharingPromotionType: {
    [CarSharingPromotionType.SHARING_SITE]: 'Börse im Intranet',
    [CarSharingPromotionType.OTHER]: 'Durch Anreize',
  },
  UserRole: {
    [UserRole.TRAFFICON_ADMIN]: 'Trafficon Admin',
    [UserRole.IVM_ADMIN]: 'ivm Admin',
    [UserRole.IVM_USER]: 'ivm Benutzer/in',
    [UserRole.ORGANIZATION_USER]: 'Arbeitgeber Benutzer/in',
  },
  UserStatus: {
    [UserStatus.ACTIVE]: 'Aktiv',
    [UserStatus.INACTIVE]: 'Inaktiv',
    [UserStatus.INVITED]: 'Eingeladen',
  },
  OrganizationUserRole: {
    [OrganizationUserRole.IVM_INTERNAL]: 'ivm Intern',
    [OrganizationUserRole.IVM_CONSULTANT]: 'ivm Berater/in',
    [OrganizationUserRole.ORGANIZATION_MOBILITY_AGENT_WRITE]:
      'Arbeitgeber Mobilitätsbeauftragte/r mit Bearbeitungsrechten',
    [OrganizationUserRole.ORGANIZATION_MOBILITY_AGENT_READ]: 'Arbeitgeber Mobilitätsbeauftragte/r mit Leserechten',
    [OrganizationUserRole.ORGANIZATION_DECISION_MAKER]: 'Arbeitgeber Entscheider/in',
  },
  YesNoPartly: {
    [YesNoPartly.YES]: 'Ja',
    [YesNoPartly.NO]: 'Nein',
    [YesNoPartly.PARTLY]: 'Teilweise',
  },
  DistanceType: {
    [DistanceType.LESS_THAN_250]: '< 250 m',
    [DistanceType.BETWEEN_250_AND_500]: '250-500 m',
    [DistanceType.BETWEEN_501_AND_750]: '501-750 m',
    [DistanceType.BETWEEN_751_AND_1000]: '751-1000 m',
    [DistanceType.BETWEEN_1001_AND_1500]: '1001-1500 m',
    [DistanceType.MORE_THAN_1500]: '> 1500 m',
  },
  GoodMediumBad: {
    [GoodMediumBad.BAD]: 'Schlecht',
    [GoodMediumBad.MEDIUM]: 'Mittel',
    [GoodMediumBad.GOOD]: 'Gut',
  },
  LocationAreaType: {
    [LocationAreaType.URBAN]: 'Innerstädtisch',
    [LocationAreaType.SUBURBAN]: 'Stadtrandlage',
    [LocationAreaType.COUNTRYSIDE]: 'Ländlich/peripher',
  },
  NeighbourhoodUsage: {
    [NeighbourhoodUsage.INDUSTRY]: 'Gewerbe',
    [NeighbourhoodUsage.MIXED_USE]: 'Mischnutzung',
    [NeighbourhoodUsage.RESIDENTIAL_USE]: 'Wohnnutzung',
  },
  NeighbourhoodBicycleInfrastructure: {
    [NeighbourhoodBicycleInfrastructure.LOW_TRAFFIC]:
      'Keine Radverkehrsanlage, geringes Verkehrsaufkommen/Geschwindigkeit (Tempo 30)',
    [NeighbourhoodBicycleInfrastructure.BICYCLE_INFRASTRUCTURE]: 'Eigene Radverkehrsanlage',
    [NeighbourhoodBicycleInfrastructure.HIGH_TRAFFIC]: 'Keine Radverkehrsanlage, hohes Verkehrsaufkommen',
  },
  PedestrianPathType: {
    [PedestrianPathType.ILLUMINATED]: 'Beleuchtet',
    [PedestrianPathType.PAVED_ROAD]: 'Befestigter Weg',
    [PedestrianPathType.OTHER]: 'Sonstiges',
  },
  DurationType: {
    [DurationType.LESS_THAN_5_MIN]: '0-5 min',
    [DurationType.BETWEEN_6_AND_10_MIN]: '6-10 min',
    [DurationType.BETWEEN_11_AND_20_MIN]: '11-20 min',
    [DurationType.BETWEEN_21_AND_40_MIN]: '21-40 min',
    [DurationType.BETWEEN_41_AND_60_MIN]: '41-60 min',
    [DurationType.MORE_THAN_60_MIN]: '>60 min',
  },
  ArrivalCount: {
    [ArrivalCount.BETWEEN_0_AND_1]: '0-1',
    [ArrivalCount.BETWEEN_2_AND_5]: '2-5',
    [ArrivalCount.BETWEEN_6_AND_12]: '6-12',
    [ArrivalCount.BETWEEN_13_AND_24]: '13-24',
    [ArrivalCount.BETWEEN_25_AND_48]: '25-48',
    [ArrivalCount.MORE_THAN_48]: '>48',
  },
  FillStatus: {
    [FillStatus.EMPTY]: 'Leer',
    [FillStatus.PARTIAL]: 'Teilweise',
    [FillStatus.COMPLETE]: 'Vollständig',
  },
  IndicatorProcessStatus: {
    [IndicatorProcessStatus.EMPTY]: 'Leer',
    [IndicatorProcessStatus.RELATIONS_UPLOADED]: 'Relationen hochgeladen',
    [IndicatorProcessStatus.RELATIONS_GEOCODED]: 'Relationen geocodiert',
    [IndicatorProcessStatus.READY_FOR_CALCULATION]: 'Bereit für Berechnung',
    [IndicatorProcessStatus.CALCULATION_DONE]: 'Berechnung abgeschlossen',
    [IndicatorProcessStatus.INDICATORS_APPROVED]: 'Indikatoren freigegeben',
  },
  IndicatorProcessStatusPublic: {
    [IndicatorProcessStatus.EMPTY]: 'Erstellt',
    [IndicatorProcessStatus.RELATIONS_UPLOADED]: 'In Bearbeitung',
    [IndicatorProcessStatus.RELATIONS_GEOCODED]: 'In Bearbeitung',
    [IndicatorProcessStatus.READY_FOR_CALCULATION]: 'In Bearbeitung',
    [IndicatorProcessStatus.CALCULATION_DONE]: 'In Bearbeitung',
    [IndicatorProcessStatus.INDICATORS_APPROVED]: 'Indikatoren freigegeben',
  },
  LocationProfileStatus: {
    [LocationProfileStatus.EDITABLE]: 'In Bearbeitung',
    [LocationProfileStatus.SUBMITTED]: 'Freigegeben/in Prüfung',
    [LocationProfileStatus.CONFIRMED]: 'Geprüft',
  },
  IndicatorError: {
    [IndicatorError.NO_RESULT]: 'Kein Ergebnis',
    [IndicatorError.TECHNICAL_ERROR]: 'Technischer Fehler',
  },
  IndicatorRelationStatus: {
    [IndicatorRelationStatus.TODO]: 'Ausstehend',
    [IndicatorRelationStatus.DONE]: 'Erfolgreich',
    [IndicatorRelationStatus.ERROR]: 'Fehlgeschlagen',
  },
  validationErrors: {
    isUnique: 'Bereits vergeben',
    isAdmin: 'Admins können nicht zugeordnet werden',
  },
  IndicatorProcess: {
    arrivalSpanFrom: 'Ankunft frühestens',
    arrivalSpanTo: 'Ankunft spätestens',
  },
  IndicatorType: {
    [IndicatorType.REL]: 'REL',
    [IndicatorType.BIKE]: 'RAD',
    [IndicatorType.BR]: 'B+R',
    [IndicatorType.MIV]: 'MIV',
    [IndicatorType.PT]: 'ÖV',
    [IndicatorType.PR]: 'P+R',
    [IndicatorType.WALK]: 'FUSS',
  },
  CommuteType: {
    [CommuteType.BIKE]: 'cycling',
    [CommuteType.BIKE_AND_RIDE]: 'bike+ride',
    [CommuteType.CAR_ELECTRIC]: 'electric car',
    [CommuteType.CAR_MOTORCYCLE]: 'car or motorcycle',
    [CommuteType.CAR_POOL]: 'car pool passenger',
    [CommuteType.HOME_OFFICE]: 'home office',
    [CommuteType.PARK_AND_RIDE]: 'park+ride',
    [CommuteType.PUBLIC_TRANSPORT]: 'public transport',
    [CommuteType.WALK]: 'walking',
    [CommuteType.LONG_DISTANCE_TRANSPORT]: 'plane',
    [CommuteType.SCOOTER]: 'scooter',
    [CommuteType.MOPED]: 'moped',
  },
  CommuteTypeShort: {
    [CommuteType.BIKE]: 'bicycle',
    [CommuteType.BIKE_AND_RIDE]: 'b+r',
    [CommuteType.CAR_ELECTRIC]: 'e-car',
    [CommuteType.CAR_MOTORCYCLE]: 'car',
    [CommuteType.CAR_POOL]: 'car pool',
    [CommuteType.HOME_OFFICE]: 'home office',
    [CommuteType.PARK_AND_RIDE]: 'p+r',
    [CommuteType.PUBLIC_TRANSPORT]: 'pt',
    [CommuteType.WALK]: 'walking',
    [CommuteType.LONG_DISTANCE_TRANSPORT]: 'plane',
    [CommuteType.SCOOTER]: 'scooter',
    [CommuteType.MOPED]: 'moped',
  },
  CommuteBadgeLabelSingular: {
    [CommuteType.BIKE]: 'Travel by bicycle',
    [CommuteType.BIKE_AND_RIDE]: 'Travel by bike + ride',
    [CommuteType.CAR_ELECTRIC]: 'Travel by electric car',
    [CommuteType.CAR_MOTORCYCLE]: 'Travel by car or motorcycle',
    [CommuteType.CAR_POOL]: 'Travel as passenger of car pool',
    [CommuteType.HOME_OFFICE]: 'In home office',
    [CommuteType.PARK_AND_RIDE]: 'Travel by park + ride',
    [CommuteType.PUBLIC_TRANSPORT]: 'Travcel by public transport',
    [CommuteType.WALK]: 'Travel by foot',
    [CommuteType.LONG_DISTANCE_TRANSPORT]: 'Travel by plane',
    [CommuteType.SCOOTER]: 'Travel by scooter',
    [CommuteType.MOPED]: 'Travel by moped',
  },
  CommuteBadgeLabelPlural: {
    [CommuteType.BIKE]: 'Travels by bicycle',
    [CommuteType.BIKE_AND_RIDE]: 'Travels by bike + ride',
    [CommuteType.CAR_ELECTRIC]: 'Travels by electric car',
    [CommuteType.CAR_MOTORCYCLE]: 'Travels by car or motorcycle',
    [CommuteType.CAR_POOL]: 'Travels as passenger of car pool',
    [CommuteType.HOME_OFFICE]: 'In home office',
    [CommuteType.PARK_AND_RIDE]: 'Travels by park + ride',
    [CommuteType.PUBLIC_TRANSPORT]: 'Travcels by public transport',
    [CommuteType.WALK]: 'Travels by foot',
    [CommuteType.LONG_DISTANCE_TRANSPORT]: 'Travels by plane',
    [CommuteType.SCOOTER]: 'Travels by scooter',
    [CommuteType.MOPED]: 'Travels by moped',
  },
  MonitoringCommuteQuestion: 'Which means of transportation did you use?',
  MonitoringDistanceQuestion: 'What was the distance you travelled?',
  MonitoringDistanceLabel: 'Please consider the one-way distance',
  MonitoringAcknowledgement: 'Thank you for your input!',
  MonitoringTouchToContinue: 'Touch to continue immediately',
  MonitoringAutoForward: 'This page is displayed for {{seconds}} seconds.',
  MonitoringAutoForwardSecondLine: 'You will be automatically redirected to the home page.',
  MonitoringDailySumPreFixSingular: 'Today',
  MonitoringDailySumPreFixPlural: 'Today',
  MonitoringDailySumPostFixSingular: 'person took part in the survey',
  MonitoringDailySumPostFixPlural: 'people took part in the survey',
  MonitoringLimitReached: 'Limit reached',
  MonitoringLimitReachedDescription:
    'Due to a time limit, no further participation in the survey is currently possible. Please try again at a later date.',
  MonitoringButtonBack: 'Back',
  MonitoringButtonConfirm: 'Done',
  MonitoringButtonOverview: 'Overview',
  MonitoringNotActive: 'Monitoring not active',
  MonitoringNotActiveDescription: 'Monitoring is currently not activated for your location',
  MonitoringError: 'An error has occurred.',
  MonitoringInfoTitle: 'Information on data collection',
  MonitoringInfoParagraphOne:
    'This survey records the use of transportation and the distance between home and work or event location. The data is used to determine the use of transportation and a rough CO2 balance.',
  MonitoringInfoParagraphTwo:
    'The survey is integrated into the "Besser zur Arbeit" advisory program, a service offered by ivm GmbH Region Frankfurt RheinMain. The data is available for further use by your employer or the organizer and ivm GmbH. You do not have to provide any personal details.',
  MonitoringAnalysisSampleSize: 'sample size',
  MonitoringAnalysisFilter: 'filter',
  MonitoringAnalysisReset: 'reset',
  MonitoringAnalysisOptions: 'options',
  MonitoringAnalysisCoTwoBalance: 'CO2 balance',
  MonitoringAnalysisCoTwoBalanceFilter: 'according to filter',
  MonitoringAnalysisCoTwoBalanceYearly: 'annual extrapolation',
  MonitoringAnalysisViewSettings: 'view settings',
  MonitoringAnalysisViewIcons: 'show icons',
  MonitoringAnalysisIncludeWayBack: 'include way back',
  MonitoringAnalysisIncludeSubtitle: 'show subtitle',
  MonitoringAnalysisEnglishVersion: 'english version',
  MonitoringAnalysisAutoRefresh: 'auto refresh data',
  MonitoringAnalysisTooLessSamples:
    'The available data basis falls below the minimum sample size required for a chart display. Please adjust the filter criteria or find out about the results at a later point in the survey period.',
  MonitoringAnalysisCarbonDioxidBadgeTooltipAnnual:
    'MIV total amount of CO2 according to dashboard filter\ndivided by the days with survey results.\nCO2 price = 80€ per ton. Year = 207 working days',
  MonitoringAnalysisCarbonDioxidBadgeTooltipReduced:
    'Reduction by switching to public transport and active mobility.\nDistance < 10 km: Switch to walking, cycling, pedelec.\nDistance >= 10 km: Switch to public transport',
  MonitoringAnalysisCarbonDioxidBadgeTooltipFiltered:
    'MIV total amount of CO2 according to dashboard filter.\n CO2 price = 80 € per ton.',
  MonitoringAnalysisCarbonDioxidBadgeEcoFriendly: 'switch to eco-friendly transportation',
  MonitoringAnalysisCarbonDioxidBadgeEcoSum: 'sum',
  MonitoringAnalysisCarbonDioxidBadgeEcoExtrapolation: 'extrapolation',
  MonitoringAnalysisModalSplitTitle: 'Use of transportation',
  MonitoringAnalysisModalSplitSubtitle: 'Modal splits (number of journeys made)',
  MonitoringAnalysisActiveInactivePlotTitle: 'Comparison of active/inactive mobility',
  MonitoringAnalysisActiveInactivePlotSubtitle:
    'The active share is made up of walking, cycling/pedelec, B+R and scooters',
  MonitoringAnalysisActiveLabel: 'active',
  MonitoringAnalysisInactiveLabel: 'inactive',
  MonitoringAnalysisDistancePlotTitle: 'Distance per means of transportation',
  MonitoringAnalysisDistancePlotSubtitle: 'Distribution of distances traveled per mode of transport',
  MonitoringAnalysisMovementAmountTitle: 'Average proportion of movement',
  MonitoringAnalysisMovementAmountSubtitle: 'Average movement time',
  MonitoringAnalysisMovementAmountYaxis: 'Active mobility in minutes',
  MonitoringAnalysisMovementAmountInfo:
    'walking = 5 km/h\ncycling = 15 km/h\nscooter = 10 km/h\nB+R = assumption 1.5 km cycle path\npublic transport = assumption 750 m footpath',
  MonitoringAnalysisCarbonDioxidPlotTitle: 'CO2 related to routes',
  MonitoringAnalysisCarbonDioxidPlotSubtitle:
    'Calculation: distances traveled and CO2 emissions for means of transport',
  MonitoringAnalysisCarbonDioxidPlotNumOfRoutes: 'Number of routes',
  MonitoringAnalysisReducedCarbonDioxidPlotTitle: 'CO2 reduction',
  MonitoringAnalysisReducedCarbonDioxidPlotSubtitle: 'when switching to public transport and active mobility',
  MonitoringAnalysisReducedCarbonDioxidPlotInfo:
    'Distance < 10 km: switch to walking, cycling, pedelec\nDistance >= 10 km: switch to PT',
  MonitoringAnalysisReducedCarbonDioxidPlotNormal: 'CO2 normal',
  MonitoringAnalysisReducedCarbonDioxidPlotReduced: 'CO2 reduced',
  MonitoringAnalysisModalSplitProgressPlotTitle: 'Development of transport mode shares',
  MonitoringAnalysisModalSplitProgressPlotPlotSubtitle: 'Number of journeys per mode of transport over time',
};
