import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { HandleDownload } from '@/services/DownloadHandler';
import { MonitoringAnalysisData } from '@/services/api';

type ModalSplitProps = {
  data: MonitoringAnalysisData[];
  showSubtitle: boolean;
};

export function ModalSplitPlot({ data, showSubtitle }: ModalSplitProps) {
  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const plotData = data.filter((obj) => obj.count > 0);
  const filterdData = [
    {
      values: plotData.map((obj) => obj.count),
      labels: plotData.map((obj) => t(`CommuteTypeShort.${obj.commuteType}`)),
      type: 'pie',
      hole: 0.7,
      sort: false,
      texttemplate: '%{percent:.0%}',
      marker: {
        colors: plotData.map((obj) => CommuteUtils[obj.commuteType].color),
        line: {
          color: theme.palette.tertiary.light,
          width: 1,
        },
      },
      hoverinfo: 'label+value+percent',
    },
  ];

  const header = ['Verkehrsmittel', 'Anteil', 'in Prozent'];
  const csvData = filterdData.flatMap((param) => {
    const total = param.values.reduce((acc, currentValue) => acc + currentValue, 0);

    return param.values.map((value, index) => ({
      label: param.labels[index],
      values: value,
      percent: Number(((value / total) * 100).toFixed(1)).toLocaleString('de-DE'),
    }));
  });

  return (
    <>
      <Button onClick={() => HandleDownload({ csvData, header, name: 'Verkehrsmittelnutzung' })}>Download CSV</Button>
      <Plot
        data={filterdData as any}
        layout={{
          title: `${t('MonitoringAnalysisModalSplitTitle')}${
            showSubtitle ? `<br><sub>${t('MonitoringAnalysisModalSplitSubtitle')}</sub>` : ''
          }`,
          showlegend: true,
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
        config={{ locale: i18n.language }}
      />
    </>
  );
}
